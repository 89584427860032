<template>
<div class="login_box mx-auto">
    <p class="text-center">ログイン画面</p>
    <v-text-field label="ログインID" v-model="name" outlined />
    <v-text-field label="パスワード" v-model="pass" outlined type="passward"/>
    <v-btn block large @click="save()">ログイン</v-btn>
</div>
</template>

<script>
import axios from "axios"
import d from "@/d"

export default {
    data() {
        return {
            name: "",
            pass: "",
        }
    },
   async mounted() {
       await d.sleep(300)
        if (this.login) {
            this.$router.push('/master/blog')
        }
    },
    methods: {
        async save() {
            const data = {
                name: this.name,
                pass: this.pass
            }
            const response = await axios.post('/master/login', data)

            if (response.data.ok) {
                this.$router.push('/master/blog')
            }else{
                alert("IDまたはパスワードが違います")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login_box {
    max-width: 600px;
    margin-top: 150px;
}
</style>
